import React from 'react';
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
// import Contact from "./Contact";
import TeamDetail from "./TeamDetail";
import MyElla from "./MyElla";
// import Login  from "../components/accounts./Login";
import Test from './Test';
// import Nfts from "./Nfts";

const routes = [
  { path: '/', component: <HomeOne />},
  { path: '/home-v2', component: <HomeTwo />},
  { path: '/home-v3', component: <HomeThree />},
  { path: '/about', component: <AboutOne />},
  { path: '/about-v2', component: <AboutTwo />},
  { path: '/coming-soon', component: <ComingSoon />},
  { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  { path: '/404', component: <Page404 />},
  { path: '/road-map-v1', component: <RoadMapOne />},
  { path: '/road-map', component: <RoadMapTwo />},
  { path: '/collection', component: <NftItem />},
  // { path: '/collection', component: <Nfts />},
  { path: '/collection/:tokenId', component: <NftItemDetails />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  // { path: '/contact', component: <Contact />},
  { path: '/team-details', component: <TeamDetail />},
  // { path: '/login', component: <Login />},
  { path: '/my-ella', component: <MyElla />},
  { path: '/test', component: <Test />},
 

  

]

export default routes;