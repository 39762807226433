const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Set up your wallet',
        text : 'Wallet that is functional for NFT purchasing.',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Create your collection',
        text : 'Setting up your NFT collection and creating NFTs on NFTs is easy! This guide explains how to set up your first collection'
    },
    {
        id: 3,
        numb: '03',
        title: "Add your NFT's",
        text : 'Upload your Nfts to Marketplace so that buyer can see it'
    },
    {
        id: 4,
        numb: '04',
        title: "Sell Your NFT's",
        text : 'Choose between auctions, fixed-price listings, and declining-price listings. You choose how you want to sell your NFTs!'
    },
]
export default dataWork;