import React from 'react'
import { Link } from 'react-router-dom';
import '../header/styles.scss';


const MyAccount = () => {
  return (
    
        <button className="tf-button btn-effect">
            <span className="boder-fade"></span>                                     
                <span className="effect">
                  <Link to = '/my-ella'>
                    My Account
                  </Link>
                </span>
        </button>
    
  )
}

export default MyAccount