import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { Dropdown } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import ProjectItem from '../project-item';
import '../../../scss/components/form.scss'
import Button from '../../../components/button';
// import { Modal } from "react-bootstrap";

Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;

    const [visible , setVisible] = useState(24);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 8);
        
    }
    // const [modalShow, setModalShow] = useState(false);
    return (
        <section className="tf-section tf-project home-2 nft-item">
            <div className="container">
                {/* <div className='myella'>
                    <h6 className='margin-top'>All Category</h6>
                </div>
                <div className='collection-header'>
                    <button className='margin'>
                        Rainbella Team
                    </button>
                    <button className='margin'>
                        Saber Ella Team
                    </button>
                    <button className='margin'>
                        Genesis Team
                    </button>
                    <button className='margin'>
                        Defender Team
                    </button>
                    <button className='margin'>
                        Cyborg Team
                    </button>

                </div> */}


                {/* <div className="collection-header">
                    <div className="seclect-box effect" data-aos="fade-in" data-aos-duration="800">
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Rainbella Team
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Saber Ella Team 
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Genesis Team
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Cyborg Team  
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Defender Team  
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Hologram Team  
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Legendary Team  
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Stone Team  
                            </Dropdown.Item>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Item href="#">
                            Outline Team   
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                </div> */}
                <div className="row margintop">
                    <div className="col-md-4">
                    <div className="widget widget-search" data-aos="fade-in" data-aos-duration="800">
                                {/* <div className=" space space-btn ">
                                    <Link to="" className="tf-button-st2 btn-effect " data-toggle="modal" data-target="#popup_bid" onClick={()=> setModalShow(true)} ><span className="effect">Marketplace</span></Link>
                                    
                                    
                                </div> */}
                                {/* <Modal
                show={modalShow}
                onHide={setModalShow}
            >
            <Modal.Header closeButton></Modal.Header>

                        <div className="modal-body center">
                                <div className="box-wallet-inner">
                                <div className="sc-box">
                                        <div className="img"><a href="https://opensea.io/collection/ella-ai" target="_blank" rel="noopener noreferrer">
                                            <img src="./image/opensea.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://opensea.io/collection/ella-ai" target="_blank" rel="noopener noreferrer">Opensea</a></h9>
                                        
                                    </div>
                                    <div className="sc-box">
                                        <div className="img"><a href="https://rarible.com/ella-ai" target="_blank" rel="noopener noreferrer">
                                            <img src="./image/rarible.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://rarible.com/ella-ai" target="_blank" rel="noopener noreferrer"> Rarible</a></h9>
                                       
                                    </div>
                                    <div className="sc-box">
                                        <div className="img"><a href="https://app.refinable.com/collections/ella" target="_blank" rel="noopener noreferrer"> 
                                            <img src="./image/refinable.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://app.refinable.com/collections/ella" target="_blank" rel="noopener noreferrer"> Refinable</a></h9>                                      
                                    </div>
                                </div>  
                            </div>
    </Modal>  */}
            
                            {/* <form action="#">
                                <input type="text" placeholder="Search" required="" />
                                <button><i className="fal fa-search"></i></button>
                            </form> */}
                        </div>
                    </div>

                    <div className="col-md-8 z-index">
                        <div className="seclect-box" data-aos="fade-in" data-aos-duration="800">
                            {/* <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    All Categories       
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#delete_client">Planets Name</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Rainbella Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Saber Ella Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Genesis Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Defender Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Cyborgs Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Hologram Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Legendary Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Stone Team</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Outline Team</li>
                                </Dropdown.Item>
                               

                                </Dropdown.Menu>
                            </Dropdown> */}

                            {/* <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                Default sorting   
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#delete_client">Sort by Popularity</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Sort by Latest</li>
                                </Dropdown.Item>
                                <Dropdown.Item href="#">
                                    <li data-toggle="modal" data-target="#edit_client">Sort by View</li>
                                </Dropdown.Item>
                                
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                    </div>

                    {
                        data.slice(0,visible).map(item => (
                            <div key={item.id} className="col-md-3">
                                <ProjectItem item={item} />
                            </div>
                        ))
                    }
                    {
                        visible < data.length && 
                        <div className="col-md-12">
                            <div className="btn-about center m-t16" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
                                <Button title='Load More' path='#' onClick={showMoreItems} />
                            </div>
                        </div>
                    }
                    
                </div>
            </div>
        </section>
    );
}

export default Project;