import React, {useState, useEffect } from 'react'
import img1 from '../assets/images/Copy_of_Unnamed_Design_1.gif';
import legendary from '../assets/images/common/5.png';
import defender from '../assets/images/common/defender.png';
import stone from '../assets/images/common/stone.png';
import hologram from '../assets/images/common/hologram.png';
import sabela from '../assets/images/common/sabela.png';
import outline from '../assets/images/common/outline.png';
import genesis from '../assets/images/common/genesis.png';
import cyborg from '../assets/images/common/cyborg.png';
import rainbella from '../assets/images/common/rainbella.png';
import { Link } from 'react-router-dom';

const Test = () => {
    
    
    const[name, setName] = useState();
    const [item, setItem] = useState([]);
    const [filter, setFilter] =useState(item);
    const [loading, setLoading] = useState(false)
    let componentMounted =true;

    useEffect(() => {
        const getProduct = async() => {
            setLoading(true)
            const response =await fetch(`https://api.ella.ai/nfts?filters${name}`);
            if(componentMounted){
                const nfts =  response.data.data
                setItem(nfts)
                setFilter(await response.json())
                setLoading(false)
                console.log(filter)
            }
            return () =>{
                componentMounted = false;
            }
        }
        getProduct();
    }, []);

    const Loading =() => {
        return(
            <>
                Loading ....
            </>
        )
    }
    const filterProduct =(cat) =>{
        const updatedList = item.filter((x) =>x.category === cat);
        setFilter(updatedList)
    }

    const ShowProducts =() => {
        return(
        <>
        <div className='collection-header'>
                    <div className='button'>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() =>setFilter(item)}>
                            <span className="boder-fade"></span>   
                            <img src={img1} alt=""height="30" width="50" className='img-pngg'/>                         
                                <span className="effect">
                                   All Nfts
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() =>filterProduct('Rainbella')}>
                            <span className="boder-fade"></span>  
                            <img src={rainbella} alt=""height="30" width="50" className='img-pngg'/>                                     
                                <span className="effect">
                                Rainbella
                            </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() =>filterProduct('Rainbella')}>
                            <span className="boder-fade"></span>   
                            <img src={outline} alt=""height="30" width="50" className='img-pngg'/>                                    
                                <span className="effect">
                                    Outline
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() =>filterProduct('Rainbella')}>
                            <span className="boder-fade"></span>   
                            <img src={genesis} alt=""height="30" width="50" className='img-pngg'/>                                    
                                <span className="effect">
                                    Genesis
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() =>setFilter(item)}>
                            <span className="boder-fade"></span> 
                            <img src={defender} alt=""height="30" width="50" className='img-pngg'/>                                      
                                <span className="effect">
                                    Defender
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left">
                            <span className="boder-fade"></span> 
                            <img src={cyborg} alt=""height="30" width="50" className='img-pngg'/>                                      
                                <span className="effect">
                                    Cyborg
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left">
                            <span className="boder-fade"></span>   
                            <img src={hologram} alt=""height="30" width="50" className='img-pngg'/>                                    
                                <span className="effect">
                                    Hologram
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left">
                            <span className="boder-fade"></span> 
                            <img src={legendary} alt=""height="30" width="50" className='img-pngg'/>                                       
                                <span className="effect">
                                    Legendary
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left">
                            <span className="boder-fade"></span>  
                            <img src={stone} alt=""height="30" width="50" className='img-pngg'/>                                     
                                <span className="effect">
                                    Stone 
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left">
                            <span className="boder-fade"></span>  
                            <img src={sabela} alt=""height="30" width="50" className='img-pngg'/>                                     
                                <span className="effect">
                                   Sabella
                                </span>
                        </button>
                    </div>
                </div>
                {filter.map((item) => {
                    return(
                        <>
                        <div className="container">
                            <div className='row position align'>
                                <div className='col-md-3 '>
                                <Link to= {`/collection/${item.token_id}`}>
                                    <div className="img-box">  
                                        <img src={item.image} alt="ella" />
                                        <Link to= {`/collection/${item.token_id}`}>
                                        <div className="content">
                                            {item.name}
                                        </div> 
                                        </Link>             
                                        <Link to= {`/collection/${item.token_id}`}>
                                        <div className='creator'>
                                            {item.token_id}
                                        </div>    
                                        </Link>     
                                    </div>
                                    </Link>
                                </div>
                                
                            </div>
                       
                        </div>
                        </>
                    )
            })}
        </>
        )
    }
  return (
    <div className='page-nft'>
        <div className='container'>
            {loading ? <ShowProducts/>  : <ShowProducts/>}
        </div>
    </div>
  )
}

export default Test