const dataFaq = [
    {
        id: 0,
        title: 'What is the Ella NFT collection?',
        content: 'Ella is a collection of 5,200 Ella NFTs—unique digital collectibles living on the Polygon blockchain. Your Ella doubles as your Ella Network clearance card, and grants access to clearance-only benefits. The first of which is your Ella avatar profile image, then access to your unique Ellas clearance strip; a short story, part of the greater picture. Ella holders will also have first dibs on the Ella collectible card packs & Ella Three D, for our future role playing game. Future areas and perks can be unlocked by the community through roadmap activation.',
        
    },
    {
        id: 1,
        title: 'What is a NFT?',
        content: 'NFTs (Non-Fungible Tokens) are unique digital assets that are secured and contained on the blockchain. The blockchain verifies ownership, provenance, and transaction history in relation to the NFT.',
    },
    {
        id: 2,
        title: 'What is Blockchain?',
        content: 'Blockchain is a system of recording information in a way that makes it difficult or nearly impossible to change, hack, or cheat the system. A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the entire network of computer systems on the blockchain.',
    },
    {
        id: 3,
        title: 'What is wallet?',
        content: 'Your crypto wallet is where you can view your cryptocurrency assets and transactions, all in one place. It’s also where you confirm any transactions in process. Different wallets have different features. For example, some allow you to view NFTs from a smartphone. Popular wallets include Coinbase and Metamask.',
    },
    {
        id: 4,
        title: 'What is minting?',
        content: 'NFTs are created through a process called minting. On the Ethereum network, a command is run that creates the NFT as an immutable record, providing it with a unique hash that contains all of the metadata. The metadata stores key contextual information about your NFT, like its current ownership and transaction history. Sites including opensea.io and etherscan.io allow you to view your NFT collection and NFTs in other collections.',
    },
    {
        id: 6,
        title: 'What is ‘Gas’ or minting fees?',
        content: 'The blockchain depends on processing power provided by a decentralized network of computers. Minting fees, often called “gas fees” are what the members of this network earn in return for the processing power they provide. Gas fees can fluctuate according to the amount of traffic on the blockchain at any given moment but cannot be avoided which is why weve decided to use gasless minting using Polygon.',
    },
    {
        id: 7,
        title: 'What is Gas-Free?',
        content: 'Our contract is aligned masked with opensea protocol for gas free transactions inside opensea',
    },
    {
        id: 8,
        title: 'What is Polygon (formerly MATIC)?',
        content: 'Polygon believes in Web3 for all. Polygon is a decentralised Ethereum scaling platform that enables developers to build scalable user-friendly dApps with low transaction fees without ever sacrificing on security.',
    },
    {
        id: 9,
        title: 'What is OpenSea?',
        content: 'OpenSea is an online marketplace that allows users to purchase, sell, and trade non-fungible tokens (NFTs). Users can transact a variety of different digital collectibles, including art, music, domain names, trading cards, and dozens more.',
    },
    {
        id: 10,
        title: 'How to buy Ella using WETH on OpenSea?',
        content: '',
    },
    {
        id: 11,
        title: 'What wallets do we support?',
        content: 'At the moment, we support MetaMask with plans to add others in the future.',
    },
    {
        id: 12,
        title: 'How can I get ETH (Ether)?',
        content: 'First, you must purchase Ethereum (ETH) through an online exchange. We recommend using Coinbase. Once you have purchased ETH, you will need to have it in your coinbase.com wallet in order to buy an NFT token. Please note there are limits on the amounts of ETH you can buy and sell based on your account and the platform you’re using to purchase ETH. There are also time delays from when you buy your ETH to when you can transfer it to your wallet to make NFT purchases. Most platforms require you to hold the money in your wallet before you can transfer it to use for purchases.',
    },
    {
        id: 13,
        title: 'What can I do with my Ella NFT?',
        content: 'These NFTs can be collected.',
    },
    {
        id: 14,
        title: 'What makes an NFT different from other kinds of digital art?',
        content: 'When you collect an NFT, your artwork has a public record on the blockchain it was created on. This makes provenance public and verifiable and permits for instantaneous transactions on the secondary market.',
    },
    {
        id: 15,
        title: 'How can I protect my NFTs?',
        content: 'Your digital wallet will come with a 12-24 word ‘seed phrase’ that acts as the password to your wallet. Never, ever share this seed phrase with anyone. We also recommend keeping a written, physical copy of your seed phrase for access offline. If another person gains access to your seed phrase they can take control of your wallet and everything it contains.',
    },
    {
        id: 16,
        title: 'Where is my NFT collection?',
        content: 'You can view your NFTs directly in your wallet, either on a smartphone or desktop computer. You may use digital marketplaces that aggregate digital collectibles and NFTs. Popular examples include opensea.io and etherscan.io.',
    },
]

export default dataFaq;