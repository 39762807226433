import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

ProjectItem.propTypes = {
    item: PropTypes.object,
};

function ProjectItem(props) {

    const {item} = props;

    return (
        <Link to= {`/collection/${item.token_id}`}>
            <div className="img-box">
            
                <img src={item.image} alt="ella"className='border-r'/>
            
                <Link to= {`/collection/${item.token_id}`}>
                    <div className='col-grid'>
                        <div className="content">
                            {item.name}
                        </div>
                        <div className='creator'>
                            {item.token_id}
                        </div>
                    </div>
                </Link>
            </div>
        </Link>
        
    );
}

export default ProjectItem;