import img1 from '../images/common/1.png'
import img2 from '../images/common/2.png'
import img3 from '../images/common/3.png'
import img4 from '../images/common/4.png'
import img5 from '../images/common/5.png'
import img6 from '../images/common/6.png'
import img7 from '../images/common/7.png'
import img8 from '../images/common/8.png'
import img9 from '../images/common/9.png'
import img10 from '../images/common/10.png'
import img11 from '../images/common/11.png'
import img12 from '../images/common/12.png'
import img13 from '../images/common/13.png'
import img14 from '../images/common/14.png'
import img15 from '../images/common/15.png'
import img16 from '../images/common/16.png'
import img17 from '../images/common/17.png'

const dataProject = [
    {
        id: 1,
        title: 'Rock Ella 23',
        img: img1,
        detail: "Details",
    },
    {
        id: 2,
        title: 'Sea Explorer Ella 24',
        img: img2,
        detail: "Details",
    },
    {
        id: 3,
        title: 'Ella Pac 25',
        img: img3,
        detail: "Details",
    },
    {
        id: 4,
        title: 'Wonder Ella 26',
        img: img4,
        detail: "Details",
    },
    {
        id: 5,
        title: 'Dark Stones Ella 27',
        img: img5,
        detail: "Details",
    },
    {
        id: 6,
        title: 'Ella Police 28',
        img: img6,
        detail: "Details",
    },
    {
        id: 7,
        title: 'Penguin Ella 29',
        img: img7,
        detail: "Details",
    },
    {
        id: 8,
        title: 'Astro Ella 30',
        img: img8,
        detail: "Details",
    },

    {
        id: 9,
        title: 'Prophetic Ella 31',
        img: img9,
        detail: "Details",
    },
    {
        id: 10,
        title: 'Monkey King Ella 32',
        img: img10,
        detail: "Details",
    },
    {
        id: 11,
        title: 'Joker Ella 33',
        img: img11,
        detail: "Details",
    },
    {
        id: 12,
        title: 'Mommy Ella 34',
        img: img12,
        detail: "Details",
    },
    {
        id: 13,
        title: 'Ella Cyclops 35',
        img: img13,
        detail: "Details",
    },
    {
        id: 14,
        title: 'Aqua Ella 36',
        img: img14,
        detail: "Details",
    },
    {
        id: 15,
        title: 'Indian Ella 37',
        img: img15,
        detail: "Details",
    },
    {
        id: 16,
        title: 'Pirate Ella 38',
        img: img16,
        detail: "Details",
    },
    {
        id: 17,
        title: 'Cowboy Ella 39',
        img: img17,
        detail: "Details",
    },
    {
        id: 18,
        title: 'Rock Ella 23',
        img: img1,
        detail: "Details",
    },
    {
        id: 19,
        title: 'Sea Explorer Ella 24',
        img: img2,
        detail: "Details",
    },
    {
        id: 20,
        title: 'Ella Pac 25',
        img: img3,
        detail: "Details",
    },
    {
        id: 21,
        title: 'Wonder Ella 26',
        img: img4,
        detail: "Details",
    },
    {
        id: 22,
        title: 'Dark Stones Ella 27',
        img: img5,
        detail: "Details",
    },
    {
        id: 23,
        title: 'Ella Police 28',
        img: img6,
        detail: "Details",
    },
    {
        id: 24,
        title: 'Penguin Ella 29',
        img: img7,
        detail: "Details",
    },
    {
        id: 25,
        title: 'Astro Ella 30',
        img: img8,
        detail: "Details",
    },
    {
        id: 26,
        title: 'Prophetic Ella 31',
        img: img9,
        detail: "Details",
    },
    {
        id: 27,
        title: 'Monkey King Ella 32',
        img: img10,
        detail: "Details",
    },
    {
        id: 28,
        title: 'Joker Ella 33',
        img: img11,
        detail: "Details",
    },
    {
        id: 29,
        title: 'Mommy Ella 34',
        img: img12,
        detail: "Details",
    },
    {
        id: 30,
        title: 'Ella Cyclops 35',
        img: img13,
        detail: "Details",
    },
    {
        id: 31,
        title: 'Aqua Ella 36',
        img: img14,
        detail: "Details",
    },
    {
        id: 32,
        title: 'Indian Ella 37',
        img: img15,
        detail: "Details",
    },
    {
        id: 33,
        title: 'Pirate Ella 38',
        img: img16,
        detail: "Details",
    },
    {
        id: 34,
        title: 'Cowboy Ella 39',
        img: img17,
        detail: "Details",
    },
]
// import axios from "axios";
// const BASE_URL = 'https://api.ella.ai/nfts';

// export default axios.create({
//     baseURL: BASE_URL,
//     headers: {
//         'Content-Type': 'application/json',
//         'Accept': 'application/json'
//     }
// })

 export default dataProject;