import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProjectItem from '../project-item';
import '../../../scss/components/form.scss'
import Button from '../../../components/button';


Project.propTypes = {
    data: PropTypes.array,
};

function Project(props) {
    const {data} = props;

    const [visible , setVisible] = useState(48);
    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 4);
    }
    
    return (
        <section className="tf-section tf-project home-2 nft-item">
            <div className="container">
                <div className="row">
                    {
                        data.slice(0,visible).map(item => (
                            <div key={item.id} className="col-md-3">
                                <ProjectItem item={item} />
                            </div>
                        ))
                    }
                    {
                        visible < data.length && 
                        <div className="col-md-12">
                            <div className="btn-about center m-t16" data-aos="fade-up" data-aos-delay="300" data-aos-duration="800">
                                <Button title='Load More' path='#' onClick={showMoreItems} />
                            </div>
                        </div>
                    }
                    
                </div>
            </div>
        </section>
    );
}

export default Project;