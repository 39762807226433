const dataAbout = [
    {
        id: 1,
        title: 'Unique 1/1 Designs',
        desc: '5200 NFTs minted on Polygon. Each Ella & Team is uniquely designed and programmatically assigned to a world. All Ellas are dope, but some are fu*kin fire.',
        class: 'active'
    },
    {
        id: 2,
        title: 'Ella Badges',
        desc: 'Each Ella is part of a unique 1 of 9 team and operates on 1 of 9 world in the Ella Network. Join a team & start exploring',
    },
    {
        id: 3,
        title: 'Rights Included',
        desc: 'Ownership and commercial usage rights given to the consumer over their NFT. To access members-only areas such as the Ella Network, holders will need to be signed into their Metamask Wallet. ',
    },
    {
        id: 4,
        title: 'Fair Launch & Fair Distribution',
        desc: 'The Ellas are stored as ERC-721 tokens on the Polygon blockchain and hosted on IPFS. There are no price tiers; Ella membership costs the same for everyone. Purchasing an Ella costs 0.02 ETH.',
    },
    {
        id: 4,
        title: 'Note:',
        desc: 'Fifty Two randomized Ellas are being withheld from public sale. These will be used for giveaways, exploration rewards—and for the creators Ella clearance.',
    },
]

export default dataAbout;