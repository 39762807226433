import React , { useState , useEffect } from 'react';
import Login from '../accounts/Login'
import { Link , NavLink } from 'react-router-dom';
import menus from '../../pages/menu';
// import {ethers} from 'ethers'
import './styles.scss';
import logo from '../../assets/images/logo/1.png'
import MyAccount from '../accounts/MyAccount'



const Header = (props) => {
    
    const [isConnected, setIsConnected] =useState(false);
  

    const getAddress= () => {
        setWalletAdress(address.substring(0,2) +'..'+ address.substring(address.length - 4))
    }
   
    const onLogin =  (provider) => {
        setIsConnected(true);
    };

    const onLogout = () => {
        setIsConnected(false);
    }

    const [address, setWalletAdress] = useState(null)
    const [scroll, setScroll] = useState(false);
        useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 300);
        });
        return () => {
            setScroll({});
        }
    }, []);
    
    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
        
      };

    
    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index); 
    };
    return (
        <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div id="site-header-inner">
                    <div className="logo">
                        <NavLink to="/"><img src={logo} alt="ella" /></NavLink>
                    </div>
                    <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : 'inactive'}`} >
                        <ul id="menu-primary-menu" className="menu">

                            {
                                menus.map((data,idx) => (
                                    <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`} 
                                    
                                    >
                                        <Link to={data.links}>{data.name}</Link>
                                        {
                                            data.namesub &&
                                            <ul className="sub-menu">
                                                {
                                                    data.namesub.map((submenu) => (
                                                        <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
                                                    ))
                                                }
                                            </ul>
                                        }
                                        
                                    </li>
                                ))
                            }
                            {/* <Link to = '/my-ella'>
                                 <li className='menu-item ella'> My Ella</li>
                            </Link> */}
                        </ul>
                        
                    </nav>
                    
                    {!isConnected && < Login onLogin = {onLogin} onLogout = {onLogout}/>}
                    {isConnected && <MyAccount getAddress={getAddress}/>}
                    <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
                </div>
            </div>
            
        </header>
    );
};

export default Header;
