import React, { useState } from 'react';
import PropTypes from 'prop-types';
import img1 from '../../../assets/images/common/Spider.png';
import img2 from '../../../assets/images/common/Kev.png';
import img3 from '../../../assets/images/common/ken.png';
import img4 from '../../../assets/images/common/kelly.jpg'
// import TeamItem from '../team-item';
import '../team-item/styles.scss';

Team.propTypes = {
    data: PropTypes.array,
};

function Team(props) {

    const {data} = props;

    const [dataBlock] = useState({
        subtitle: 'Our team',
        title: 'Meet our TEAM',
    })
    return (
        <section className="tf-section tf-team home-2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title st2 mb-51" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                            <div className="col-xl-3 col-md-6 " data-aos="fade-up" data-aos-duration="800">
                                <div className="team-box">
                                    <div className="image">
                                        <img src={img1} alt="Crybox" />
                                    </div>
                                    <div className="content">
                                        <div className="h8">Hankella</div>
                                            <p>Founder & CeO</p>
                                            <ul className="social">
                                                <li >
                                                <a href="https://twitter.com/ella5200" target="_blank" rel="noopener noreferrer">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.173 4.01621C22.2015 3.3728 22.971 2.35967 23.338 1.16598C22.3715 1.76605 21.3141 2.18875 20.2115 2.41581C18.6828 0.723595 16.2607 0.311787 14.2986 1.41049C12.3365 2.5092 11.3202 4.84642 11.8176 7.11616C7.85881 6.90819 4.17053 4.95138 1.67052 1.73267C0.365817 4.08755 1.03255 7.09789 3.19419 8.61211C2.41252 8.58582 1.64818 8.36436 0.964911 7.96619C0.964911 7.9878 0.964911 8.00941 0.964911 8.03102C0.965363 10.484 2.6175 12.597 4.91519 13.0832C4.19015 13.2896 3.42963 13.32 2.69165 13.172C3.33783 15.2698 5.18545 16.7069 7.29133 16.7498C5.54718 18.1823 3.39322 18.9591 1.17602 18.9553C0.783024 18.9559 0.390336 18.9322 0 18.8845C2.25152 20.3985 4.87202 21.2021 7.54833 21.1992C11.2717 21.226 14.85 19.6899 17.4828 16.9347C20.1156 14.1795 21.5832 10.4349 21.5573 6.53868C21.5573 6.31536 21.5523 6.09325 21.5424 5.87234C22.5067 5.1431 23.3389 4.2397 24 3.20461C23.1017 3.62129 22.1487 3.89486 21.173 4.01621Z" fill="white"/>
                                                    </svg>                            
                                                </a>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 " data-aos="fade-up" data-aos-duration="800">
                                <div className="team-box">
                                    <div className="image">
                                        <img src={img2} alt="Crybox" />
                                    </div>
                                    <div className="content">
                                        <div className="h8">Dev Ella</div>
                                            <p>Blockchain Developer</p>
                                            <ul className="social">
                                                <li >
                                                <a href="https://twitter.com/ellacollector" target="_blank" rel="noopener noreferrer">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.173 4.01621C22.2015 3.3728 22.971 2.35967 23.338 1.16598C22.3715 1.76605 21.3141 2.18875 20.2115 2.41581C18.6828 0.723595 16.2607 0.311787 14.2986 1.41049C12.3365 2.5092 11.3202 4.84642 11.8176 7.11616C7.85881 6.90819 4.17053 4.95138 1.67052 1.73267C0.365817 4.08755 1.03255 7.09789 3.19419 8.61211C2.41252 8.58582 1.64818 8.36436 0.964911 7.96619C0.964911 7.9878 0.964911 8.00941 0.964911 8.03102C0.965363 10.484 2.6175 12.597 4.91519 13.0832C4.19015 13.2896 3.42963 13.32 2.69165 13.172C3.33783 15.2698 5.18545 16.7069 7.29133 16.7498C5.54718 18.1823 3.39322 18.9591 1.17602 18.9553C0.783024 18.9559 0.390336 18.9322 0 18.8845C2.25152 20.3985 4.87202 21.2021 7.54833 21.1992C11.2717 21.226 14.85 19.6899 17.4828 16.9347C20.1156 14.1795 21.5832 10.4349 21.5573 6.53868C21.5573 6.31536 21.5523 6.09325 21.5424 5.87234C22.5067 5.1431 23.3389 4.2397 24 3.20461C23.1017 3.62129 22.1487 3.89486 21.173 4.01621Z" fill="white"/>
                                                    </svg>                            
                                                </a>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 " data-aos="fade-up" data-aos-duration="800">
                                <div className="team-box">
                                    <div className="image">
                                        <img src={img3} alt="Crybox" />
                                    </div>
                                    <div className="content">
                                        <div className="h8">Ace Ella</div>
                                            <p>Front End Developer</p>
                                            <ul className="social">
                                                <li >
                                                <a href="https://twitter.com/ModeratorElla" target="_blank" rel="noopener noreferrer">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.173 4.01621C22.2015 3.3728 22.971 2.35967 23.338 1.16598C22.3715 1.76605 21.3141 2.18875 20.2115 2.41581C18.6828 0.723595 16.2607 0.311787 14.2986 1.41049C12.3365 2.5092 11.3202 4.84642 11.8176 7.11616C7.85881 6.90819 4.17053 4.95138 1.67052 1.73267C0.365817 4.08755 1.03255 7.09789 3.19419 8.61211C2.41252 8.58582 1.64818 8.36436 0.964911 7.96619C0.964911 7.9878 0.964911 8.00941 0.964911 8.03102C0.965363 10.484 2.6175 12.597 4.91519 13.0832C4.19015 13.2896 3.42963 13.32 2.69165 13.172C3.33783 15.2698 5.18545 16.7069 7.29133 16.7498C5.54718 18.1823 3.39322 18.9591 1.17602 18.9553C0.783024 18.9559 0.390336 18.9322 0 18.8845C2.25152 20.3985 4.87202 21.2021 7.54833 21.1992C11.2717 21.226 14.85 19.6899 17.4828 16.9347C20.1156 14.1795 21.5832 10.4349 21.5573 6.53868C21.5573 6.31536 21.5523 6.09325 21.5424 5.87234C22.5067 5.1431 23.3389 4.2397 24 3.20461C23.1017 3.62129 22.1487 3.89486 21.173 4.01621Z" fill="white"/>
                                                    </svg>                            
                                                </a>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-md-6 " data-aos="fade-up" data-aos-duration="800">
                                <div className="team-box">
                                    <div className="image">
                                        <img src={img4} alt="Crybox" />
                                    </div>
                                    <div className="content">
                                        <div className="h8">Jack Ella</div>
                                            <p>Graphic Designer</p>
                                            <ul className="social">
                                                <li >
                                                <a href="https://twitter.com/ella5200" target="_blank" rel="noopener noreferrer">
                                                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.173 4.01621C22.2015 3.3728 22.971 2.35967 23.338 1.16598C22.3715 1.76605 21.3141 2.18875 20.2115 2.41581C18.6828 0.723595 16.2607 0.311787 14.2986 1.41049C12.3365 2.5092 11.3202 4.84642 11.8176 7.11616C7.85881 6.90819 4.17053 4.95138 1.67052 1.73267C0.365817 4.08755 1.03255 7.09789 3.19419 8.61211C2.41252 8.58582 1.64818 8.36436 0.964911 7.96619C0.964911 7.9878 0.964911 8.00941 0.964911 8.03102C0.965363 10.484 2.6175 12.597 4.91519 13.0832C4.19015 13.2896 3.42963 13.32 2.69165 13.172C3.33783 15.2698 5.18545 16.7069 7.29133 16.7498C5.54718 18.1823 3.39322 18.9591 1.17602 18.9553C0.783024 18.9559 0.390336 18.9322 0 18.8845C2.25152 20.3985 4.87202 21.2021 7.54833 21.1992C11.2717 21.226 14.85 19.6899 17.4828 16.9347C20.1156 14.1795 21.5832 10.4349 21.5573 6.53868C21.5573 6.31536 21.5523 6.09325 21.5424 5.87234C22.5067 5.1431 23.3389 4.2397 24 3.20461C23.1017 3.62129 22.1487 3.89486 21.173 4.01621Z" fill="white"/>
                                                    </svg>                            
                                                </a>
                                                </li>
                                            </ul>
                                    </div>
                                </div>
                            </div>
                    </div>
            </div>
        </section>
        
    );
}

export default Team;