import React, {useState, useEffect} from 'react';
import PageTitle from '../components/pagetitle';
import Project from '../features/project/myella';
import axios from 'axios';

const MyElla = () => {
    const [address] = useState(() => {
        const initialValue = localStorage.getItem("address");
        console.log(initialValue)
        return initialValue || "";
      });
    const [item, setNfts] = useState([])

    useEffect(() => {
        async function getNfts() {
           const response = await axios.get(`https://api.ella.ai/my/nfts?wallet=${address}`)
           const nfts =  response.data.data
           setNfts(nfts)
        }

        getNfts();
    },);
    return (
        <div className='page-nft'>
            <PageTitle title='My Ella Collection' />
            <div className="footer-main">
                <div className="container">
                    <h6 className="contract myella">WALLET ADDRESS : <span className ="text">{address}</span></h6>
                </div>
            </div>
            <Project data ={item}/>
        </div>
    );
}

export default MyElla;