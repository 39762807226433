import img1 from '../images/common/Spider.png';
import img2 from '../images/common/Kev.png';
import img3 from '../images/common/ken.png';
import img4 from '../images/common/kelly.jpg';


const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'Hankella',
        position: 'Founder & CeO',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: "https://twitter.com/ella5200"
            },
          //  {
          //      id: 2,
          //      icon: 'fab fa-facebook-f',
          //      link: '#'
          //  },
          //  {
         //       id: 3,
          //      icon: 'fa fa-paper-plane',
          //      link: '#'
          //  },
          //  {w
         //       id: 4,
         //       icon: 'fab fa-reddit',
          //      link: '#'
          //  },
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Dev Ella',
        position: 'Blockchain Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
          //  {
          //      id: 2,
          //      icon: 'fab fa-facebook-f',
          //      link: '#'
          //  },
          //  {
          //      id: 3,
          //      icon: 'fa fa-paper-plane',
          //      link: '#'
          //  },
          //  {
          //      id: 4,
          //      icon: 'fab fa-reddit',
          //      link: '#'
          //  },
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Ace Ella',
        position: 'Front End Developer',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
          //  {
           //     id: 2,
          //      icon: 'fab fa-facebook-f',
          //      link: '#'
          //  },
          //  {
         //       id: 3,
          //      icon: 'fa fa-paper-plane',
          //      link: '#'
          //  },
            //{
            //    id: 4,
            //    icon: 'fab fa-reddit',
            //    link: '#'
            //},
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Jack Ella',
        position: 'aw',
        listsocial: [
            {
                id: 1,
                icon: 'fab fa-twitter',
                link: '#'
            },
          
        ]
    },
    
    
   
]

export default dataTeam;