import React, {useState, useEffect} from 'react';

import '../scss/components/section.scss';
import '../scss/components/box.scss';
import Slider from '../components/slider';
import dataSlider from '../assets/fake-data/data-slider';
import About from '../features/about';
import Project from '../features/project';
// import dataProject from '../assets/fake-data/dataProject';
import dataAbout from '../assets/fake-data/data-about';
import RoadMap from '../features/roadmap';
import dataRoadmap from '../assets/fake-data/data-roadmap';
// import Work from '../features/work';
// import dataWork from '../assets/fake-data/data-work';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
// import Blog from '../features/blog';
// import dataBlog from '../assets/fake-data/data-blog';
// import Partner from '../features/partner';
// import dataPartner from '../assets/fake-data/data-partner';
// import FAQ from '../features/faq';
// import dataFaq from '../assets/fake-data/data-faq';
import axios from 'axios';

function HomeOne(props) {
    const [nfts, setNfts] = useState([])
    useEffect(() => {
        async function getNfts() {
           const response = await axios.get('https://api.ella.ai/nfts')
           const nfts =  response.data.data
           setNfts(nfts)
        }
        getNfts();
    }, []);

    return (
        <div className='home-1'>

        <Slider data={dataSlider} />
        
        <Project data={nfts} />

        <About data={dataAbout} />

        <Project data={nfts} />
        
        <Project data={nfts} />

        <RoadMap data={dataRoadmap} />

        {/* <Work data={dataWork} /> */}

        <Team data={dataTeam} />

        {/* <Blog data={dataBlog} /> */}

        { /*  <Partner data={dataPartner} /> */}

        {/* <FAQ data={dataFaq} /> */}
        
        </div>
    );
}

export default HomeOne;