const dataRoadmap = [
    {
        id: 1,
        time: 'Week 1',
        list: [
            {
                text: 'Release website and logo'
            },
            {
                text: 'Grow community'
            },
            {
                text: 'Ella drops into the deep realms.'
            },
            {
                text: 'Launched on Polygon with 5200 NFT’s'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Week 13',
        list: [
            {
                text: 'Ella Collectible Virtuals'
            },
            {
                text: 'Release the detailed collectible card packs for each of the 5200 Ella NFTs.'
            },
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Week 26',
        list: [
            {
                text: 'Ella Three D'
            },
            {
                text: 'Release the 3D variants of the 5200 Ella NFTs.'
            },
           
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Week 52',
        list: [
            {
                text: 'Ella Virtual World'
            },
            {
                text: 'An endless space in the galaxy that Ella roams, builds & earns.'
            },
           
        ],
        positon: 'right'
    },
]

export default dataRoadmap;