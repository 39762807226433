import React, {useState, useEffect} from 'react';
// import PageTitle from '../components/pagetitle';
import Project from '../features/project/nftitem';
import axios from 'axios';
import { Modal } from "react-bootstrap";
// import img1 from '../assets/images/Copy_of_Unnamed_Design_1.gif';
import legendary from '../assets/images/common/5.png';
import defender from '../assets/images/common/defender.png';
import stone from '../assets/images/common/stone.png';
import hologram from '../assets/images/common/hologram.png';
import sabela from '../assets/images/common/sabela.png';
import outline from '../assets/images/common/outline.png';
import genesis from '../assets/images/common/genesis.png';
import cyborg from '../assets/images/common/cyborg.png';
import rainbella from '../assets/images/common/rainbella.png';
//import { Autoplay } from 'swiper';
//import { Swiper, SwiperSlide } from 'swiper/react';

function NftItem() {


    const [query, setQuery] = useState("");
    console.log(query)
    
    const search = (nfts) =>{
        return item.filter((item) => item.name.toLowerCase().includes(query.toLowerCase())
    )
    }
    const [modalShow, setModalShow] = useState(false);
    const [item, setNfts] = useState([])
    const [name, setValue] = useState('');
    
    useEffect(() => {
        async function getNfts() {
           const response = await axios.get(`https://api.ella.ai/nfts?filters[team]=${name}`)
           const nfts =  response.data.data
           setNfts(nfts)
           setValue(item)
        }
        getNfts();
    }, []);

     const handleFilter = async (name) => {
        async function getNfts() {
            const response = await axios.get(`https://api.ella.ai/nfts?filters[team]=${name}`)
            const nfts =  response.data.data
            setNfts(nfts)
            setValue(query)
         }
         getNfts();
     }
    // const ShowNfts = (team) =>{
    //     return (
    //         <>
    //             <div className='collection-header'>
    //                 <div className='button'>
    //                     <button className="tf-buttonss btn-effect margin-left" onClick={() =>setFilter(item)}>
    //                         <span className="boder-fade"></span>   
    //                         <img src={img1} alt=""height="50" width="70" className='img-pngg'/>                         
    //                             <span className="effect">
    //                                All Nfts
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left" onClick={() => filterProduct("Rainbella")}>
    //                         <span className="boder-fade"></span>  
    //                         <img src={rainbella} alt=""height="50" width="70" className='img-pngg'/>                                     
    //                             <span className="effect">
    //                             Rainbella
    //                         </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span>   
    //                         <img src={outline} alt=""height="50" width="70" className='img-pngg'/>                                    
    //                             <span className="effect">
    //                                 Outline
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span>   
    //                         <img src={genesis} alt=""height="50" width="70" className='img-pngg'/>                                    
    //                             <span className="effect">
    //                                 Genesis
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span> 
    //                         <img src={defender} alt=""height="50" width="70" className='img-pngg'/>                                      
    //                             <span className="effect">
    //                                 Defender
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span> 
    //                         <img src={cyborg} alt=""height="50" width="70" className='img-pngg'/>                                      
    //                             <span className="effect">
    //                                 Cyborg
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span>   
    //                         <img src={hologram} alt=""height="0" width="70" className='img-pngg'/>                                    
    //                             <span className="effect">
    //                                 Hologram
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span> 
    //                         <img src={legendary} alt=""height="70" width="70" className='img-pngg'/>                                       
    //                             <span className="effect">
    //                                 Legendary
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span>  
    //                         <img src={stone} alt=""height="70" width="70" className='img-pngg'/>                                     
    //                             <span className="effect">
    //                                 Stone 
    //                             </span>
    //                     </button>
    //                     <button className="tf-buttonss btn-effect margin-left">
    //                         <span className="boder-fade"></span>  
    //                         <img src={sabela} alt=""height="70" width="100" className='img-pngg'/>                                     
    //                             <span className="effect">
    //                                Sabella
    //                             </span>
    //                     </button>
    //                 </div>
    //             </div>
    //             <div className="col-md-4">
    //                 <div className="widget widget-search" data-aos="fade-in" data-aos-duration="800">
    //                             <div className=" space space-btn ">
    //                                 <Link to="" className="tf-button-st2 btn-effect " data-toggle="modal" data-target="#popup_bid" onClick={()=> setModalShow(true)} ><span className="effect">Marketplace</span></Link>
                                    
                                    
    //                             </div>
    //                             <Modal
    //             show={modalShow}
    //             onHide={setModalShow}           >
    //         <Modal.Header closeButton></Modal.Header>
    //                     <div className="modal-body center">
    //                             <div className="box-wallet-inner">
    //                                 <div className="sc-box">
    //                                     <div className="img">
    //                                         <img src="./image/opensea.png" alt="ella" />
    //                                     </div>
    //                                     <h9 className="heading"><a href="https://opensea.io/collection/ella-ai">Opensea</a> </h9>                              
    //                                 </div>
    //                                 <div className="sc-box">
    //                                     <div className="img">
    //                                         <img src="./image/rarible.png" alt="ella" />
    //                                     </div>
    //                                     <h9 className="heading"><a href="https://rarible.com/ella-ai"> Rarible</a></h9>
                                       
    //                                 </div>
    //                                 <div className="sc-box">
    //                                     <div className="img">
    //                                         <img src="./image/refinable.png" alt="ella" />
    //                                     </div>
    //                                     <h9 className="heading"><a href="https://app.refinable.com/collections/ella"> Refinable</a></h9>                                     
    //                                 </div>
    //                             </div>  
    //                         </div>
    // </Modal> 
            
    //                         <form action="#" onChange={(e) => setQuery(e.target.value)}>
    //                             <input type="text" placeholder="Search" required="" />
    //                             <button ><i className="fal fa-search"></i></button>
    //                         </form>
    //                     </div>
    //                 </div>
    //         {filter.map((item) => {
    //                 return(
    //                     <>
    //                     <div className="container">
    //                         <div className='row position align'>
    //                             <div className='col-md-3 '>
    //                             <Link to= {`/collection/${item.token_id}`}>
    //                                 <div className="img-box">  
    //                                     <img src={item.image} alt="ella" />
    //                                     <Link to= {`/collection/${item.token_id}`}>
    //                                     <div className="content">
    //                                         {item.name}
    //                                     </div> 
    //                                     </Link>             
    //                                     <Link to= {`/collection/${item.token_id}`}>
    //                                     <div className='creator'>
    //                                         {item.token_id}
    //                                     </div>    
    //                                     </Link>     
    //                                 </div>
    //                                 </Link>
    //                             </div>
                                
    //                         </div>
                       
    //                     </div>
    //                     </>
    //                 )
    //         })}
    //         </>
    //     )
    // }
    
    return (
        <div className='page-nft'>
            {/* <PageTitle title='ELLA’S collection' /> */}
            <div className="container">
                <div className='myella'>
                    <h2 className='margin-top'>COLLECTION</h2>
                </div>
                <div className='collection-svg m-t100'>
                        <button className="tf-buttonss  margin-left" onClick={()=> setModalShow(true)}>
                                <span className="effect"></span>  
                                <svg xmlns="http://www.w3.org/2000/svg"width="50" height="30" viewBox="0 0 122.9 107.5" fill='none'>
                                    < path d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z" fill='white' className='effect'/>
                                </svg>      
                        </button>
                        {/* <button className="tf-buttonss  margin-left" onClick={()=> setModalShow(true)}>
                                <span className="effect"></span>  
                                <svg xmlns="http://www.w3.org/2000/svg"width="50" height="30" viewBox="0 0 122.88 48.53" fill='none'>
                                    <path xmlns="http://www.w3.org/2000/svg" d="M10.29,4.35H47.77a29.11,29.11,0,0,0-2.44,4.2h-35A6.11,6.11,0,0,0,4.2,14.64V30.31a6.11,6.11,0,0,0,6.09,6.09H46.84A29,29,0,0,0,50,40.6H10.29A10.31,10.31,0,0,1,0,30.31V14.64A10.31,10.31,0,0,1,10.29,4.35Zm5.05,11.28a1.91,1.91,0,1,1,3.82,0V29.32a1.91,1.91,0,0,1-3.82,0V15.63ZM71.93,0a21.09,21.09,0,0,1,8.15,1.62l0,0A21.31,21.31,0,0,1,93.24,21.31a21.37,21.37,0,0,1-3,11l8.25,9a1,1,0,0,1-.06,1.45l-6.06,5.53a1,1,0,0,1-1.44-.07L83,39.51A20.89,20.89,0,0,1,78.1,41.7,21.34,21.34,0,0,1,63.78,41l-.05,0A21.33,21.33,0,0,1,52.24,13.16l0-.06A21.34,21.34,0,0,1,63.78,1.62,21.13,21.13,0,0,1,71.93,0ZM84,9.28a17.14,17.14,0,0,0-5.52-3.69l-.05,0A17,17,0,0,0,59.91,9.28l0,.06a16.79,16.79,0,0,0-3.64,5.47l0,0a17.1,17.1,0,0,0,0,13A17,17,0,0,0,78.43,37,17,17,0,0,0,84,33.32l.06,0a17.18,17.18,0,0,0,3.64-5.46l0,0a17.17,17.17,0,0,0,0-13A17.28,17.28,0,0,0,84,9.28ZM105,38.5h0l-.06-.06,0,0-6-6.56a24.86,24.86,0,0,0,.84-2.43,29,29,0,0,0-1-19.5,29.49,29.49,0,0,0-3-5.57h16.91a10.31,10.31,0,0,1,10.29,10.29V30.31a10.29,10.29,0,0,1-3,7.26h0a10.24,10.24,0,0,1-7.26,3H106.3A7.37,7.37,0,0,0,105,38.5Z" fill='white'/>
                                </svg>      
                        </button> */}
                    </div>
                <div className='row'>
                <div className='collection-header'>
                    <div className='button'>
                      {/* <Swiper
                        className='swiper-portfolio s1'
                        modules={[ Autoplay ]}
                        spaceBetween={30}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                },
                        }}
                        loop
                        autoplay={{
                            delay: 2,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        speed= {20000}
                    >  
                    <SwiperSlide> */}
                        
                     <button className="tf-buttonss btn-effect" onClick={() => handleFilter('rainbella')}>
                            <span className=""></span>  
                            <img src={rainbella} alt=""height="50" width="120" className='img-pngg'/>                                     
                                <span className="effect">
                                Rainbella
                            </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() => handleFilter('outline')}>
                            <span className=""></span>   
                            <img src={outline} alt=""height="50" width="120" className='img-pngg'/>                                    
                                <span className="effect">
                                    Outline
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() => handleFilter('genesis')}>
                            <span className=""></span>   
                            <img src={genesis} alt=""height="50" width="120" className='img-pngg'/>                                    
                                <span className="effect">
                                    Genesis
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() => handleFilter('defender')} >
                            <span className=""></span> 
                            <img src={defender} alt=""height="50" width="120" className='img-pngg'/>                                      
                                <span className="effect">
                                    Defender
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() => handleFilter('cyborg')}>
                            <span className=""></span> 
                            <img src={cyborg} alt=""height="50" width="120" className='img-pngg'/>                                      
                                <span className="effect">
                                    Cyborg
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() => handleFilter('hologram')}>
                            <span className=""></span>   
                            <img src={hologram} alt=""height="50" width="120" className='img-pngg'/>                                    
                                <span className="effect">
                                    Hologram
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() => handleFilter('legendary')}>
                            <span className=""></span> 
                            <img src={legendary} alt=""height="50" width="120" className='img-pngg'/>                                       
                                <span className="effect">
                                    Legendary
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left"onClick={() => handleFilter('ella stone')}>
                            <span className=""></span>  
                            <img src={stone} alt=""height="50" width="120" className='img-pngg'/>                                     
                                <span className="effect">
                                    Stone 
                                </span>
                        </button>
                        <button className="tf-buttonss btn-effect margin-left" onClick={() => handleFilter('saber ella')}>
                            <span className=""></span>  
                            <img src={sabela} alt=""height="50" width="120" className='img-pngg'/>                                     
                                <span className="effect">
                                   Sabella
                                </span>
                        </button>
                        {/* </SwiperSlide>
                        </Swiper> */}
                        {/* <button className="tf-buttonss  margin-left" onClick={()=> setModalShow(true)}>
                                <span className="effect"></span>  
                                <svg xmlns="http://www.w3.org/2000/svg"width="50" height="30" viewBox="0 0 122.9 107.5" fill='none'>
                                    < path d="M3.9,7.9C1.8,7.9,0,6.1,0,3.9C0,1.8,1.8,0,3.9,0h10.2c0.1,0,0.3,0,0.4,0c3.6,0.1,6.8,0.8,9.5,2.5c3,1.9,5.2,4.8,6.4,9.1 c0,0.1,0,0.2,0.1,0.3l1,4H119c2.2,0,3.9,1.8,3.9,3.9c0,0.4-0.1,0.8-0.2,1.2l-10.2,41.1c-0.4,1.8-2,3-3.8,3v0H44.7 c1.4,5.2,2.8,8,4.7,9.3c2.3,1.5,6.3,1.6,13,1.5h0.1v0h45.2c2.2,0,3.9,1.8,3.9,3.9c0,2.2-1.8,3.9-3.9,3.9H62.5v0 c-8.3,0.1-13.4-0.1-17.5-2.8c-4.2-2.8-6.4-7.6-8.6-16.3l0,0L23,13.9c0-0.1,0-0.1-0.1-0.2c-0.6-2.2-1.6-3.7-3-4.5 c-1.4-0.9-3.3-1.3-5.5-1.3c-0.1,0-0.2,0-0.3,0H3.9L3.9,7.9z M96,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C86.4,92.6,90.7,88.3,96,88.3L96,88.3z M53.9,88.3c5.3,0,9.6,4.3,9.6,9.6c0,5.3-4.3,9.6-9.6,9.6 c-5.3,0-9.6-4.3-9.6-9.6C44.3,92.6,48.6,88.3,53.9,88.3L53.9,88.3z M33.7,23.7l8.9,33.5h63.1l8.3-33.5H33.7L33.7,23.7z" fill='white' className='effect'/>
                                </svg>      
                        </button>
                        <button className="tf-buttonss  margin-left" onClick={()=> setModalShow(true)}>
                                <span className="effect"></span>  
                                <svg xmlns="http://www.w3.org/2000/svg"width="50" height="30" viewBox="0 0 122.88 48.53" fill='none'>
                                    <path xmlns="http://www.w3.org/2000/svg" d="M10.29,4.35H47.77a29.11,29.11,0,0,0-2.44,4.2h-35A6.11,6.11,0,0,0,4.2,14.64V30.31a6.11,6.11,0,0,0,6.09,6.09H46.84A29,29,0,0,0,50,40.6H10.29A10.31,10.31,0,0,1,0,30.31V14.64A10.31,10.31,0,0,1,10.29,4.35Zm5.05,11.28a1.91,1.91,0,1,1,3.82,0V29.32a1.91,1.91,0,0,1-3.82,0V15.63ZM71.93,0a21.09,21.09,0,0,1,8.15,1.62l0,0A21.31,21.31,0,0,1,93.24,21.31a21.37,21.37,0,0,1-3,11l8.25,9a1,1,0,0,1-.06,1.45l-6.06,5.53a1,1,0,0,1-1.44-.07L83,39.51A20.89,20.89,0,0,1,78.1,41.7,21.34,21.34,0,0,1,63.78,41l-.05,0A21.33,21.33,0,0,1,52.24,13.16l0-.06A21.34,21.34,0,0,1,63.78,1.62,21.13,21.13,0,0,1,71.93,0ZM84,9.28a17.14,17.14,0,0,0-5.52-3.69l-.05,0A17,17,0,0,0,59.91,9.28l0,.06a16.79,16.79,0,0,0-3.64,5.47l0,0a17.1,17.1,0,0,0,0,13A17,17,0,0,0,78.43,37,17,17,0,0,0,84,33.32l.06,0a17.18,17.18,0,0,0,3.64-5.46l0,0a17.17,17.17,0,0,0,0-13A17.28,17.28,0,0,0,84,9.28ZM105,38.5h0l-.06-.06,0,0-6-6.56a24.86,24.86,0,0,0,.84-2.43,29,29,0,0,0-1-19.5,29.49,29.49,0,0,0-3-5.57h16.91a10.31,10.31,0,0,1,10.29,10.29V30.31a10.29,10.29,0,0,1-3,7.26h0a10.24,10.24,0,0,1-7.26,3H106.3A7.37,7.37,0,0,0,105,38.5Z" fill='white'/>
                                </svg>      
                        </button> */}
                      
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                    <div className="widget widget-search" data-aos="fade-in" data-aos-duration="800">
                                {/* <div className=" space space-btn ">
                                    <Link to="" className="tf-button-st2 btn-effect " data-toggle="modal" data-target="#popup_bid" onClick={()=> setModalShow(true)} ><span className="effect">Marketplace</span></Link>
                                    
                                    
                                </div> */}
                                <Modal
                show={modalShow}
                onHide={setModalShow}           >
            <Modal.Header closeButton></Modal.Header>
                        <div className="modal-body center">
                        <div className="box-wallet-inner">
                                    <div className="sc-box">
                                        <div className="img"><a href="https://opensea.io/collection/ella-ai" target="_blank" rel="noopener noreferrer">
                                            <img src="./image/opensea.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://opensea.io/collection/ella-ai" target="_blank" rel="noopener noreferrer">Opensea</a></h9>
                                        
                                    </div>
                                    <div className="sc-box">
                                        <div className="img"><a href="https://rarible.com/ella-ai" target="_blank" rel="noopener noreferrer">
                                            <img src="./image/rarible.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://rarible.com/ella-ai" target="_blank" rel="noopener noreferrer"> Rarible</a></h9>
                                       
                                    </div>
                                    <div className="sc-box">
                                        <div className="img"><a href="https://app.refinable.com/collections/ella" target="_blank" rel="noopener noreferrer"> 
                                            <img src="./image/refinable.png" alt="ella" /></a>
                                        </div>
                                        <h9 className="heading"><a href="https://app.refinable.com/collections/ella" target="_blank" rel="noopener noreferrer"> Refinable</a></h9>
                                       
                                    </div>
                                </div>   
                            </div>
    </Modal> 
            
                            <form action="#" onChange={(e) => setQuery(e.target.value)}
                                value ={query}>
                                <input type="text" placeholder="Search" required="" />
                                <button onSubmit={(search) => handleFilter(search.target.value)}><i className="fal fa-search"></i></button>
                            </form>
                        </div>
                    </div>
            <Project data={search(item)}  />
            
        </div>
        </div>
    );
}

export default NftItem;