import bgImg1 from '../images/background/Space_Station.png';
import bgImg2 from '../images/background/bg_light.png';
// import bgImg3 from '../images/background/Planet.png';
import img1 from '../images/common/nobooster.png'; 
// import img2 from '../images/common/ella_slider2.png'; 
// import img3 from '../images/common/robot.png'
const dataSlider = [
    {
        id: 1,
        title: 'Hi! Im Ella',
        desc : 'Your companion in the infinite metaverse. All 5200 of my unique inclusive variations were designed with you in mind.',
        bgImg: bgImg1,
        img : img1
    },
    // {
    //     id: 2,
    //     title: 'Ella nft collectionS for everyone',
    //     desc : 'Where you’re going is much more important than where you’ve been. Ella will help get you there..Ella have a total of 5200 asset with different kinds of traits. ',
    //     bgImg: bgImg2,
    //     img : img2,
        
    // },
    // {
    //     id: 3,
    //     title: 'Ella NFT your companion in the universe',
    //     desc : 'Where you’re going is much more important than where you’ve been. Ella will help get you there..Ella have a total of 5200 asset with different kinds of traits. Ellas with traits will be categorize in Common, Unique, Rare, Super Rare. To know more about ellas traits, ',
    //     bgImg: bgImg3,
    //     img : img3,
    // },
    // {
    //     id: 4,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg1,
    //     img : img1
    // },
    // {
    //     id: 5,
    //     title: 'CYbox nft collectionS for everyone',
    //     desc : 'Nulla ornare sagittis placerat nunc sit tempus enim. Accumsan pellentesque ipsum felis tristique at proin vel turpis.',
    //     bgImg: bgImg2,
    //     classAction: 'two'
    // },

]

export default dataSlider;