import React , {useState} from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import Button from '../../components/button';
import AboutItem from './about-item';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        {
            subtitle : 'About us',
            title: 'Welcome to the Ella Network',
            desc : 'Ella is a collection of 5,200 Ella NFTs—unique digital collectibles living on the Polygon blockchain. Your Ella doubles as your Ella Network clearance card, and grants access to clearance-only benefits. The first of which is your Ella avatar profile image, then access to your unique Ellas clearance strip; a short story, part of the greater picture. Ella holders will also have first dibs on the Ella collectible card packs & Ella Three D, for our future role playing game. Future areas and perks can be unlocked by the community through roadmap activation.'
        }
    )

    return (
        <section className="tf-section tf-about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="h8 sub-title"></p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p>{dataBlock.desc}</p>
                                <Button title="VIEW COLLECTION" path='/collection' />
                                {/* <Button title="JOIN DISCORD" path='/' /> */}
                                <button to="" className="tf-button btn-effect">
                                        <span className="boder-fade"></span>                                   
                                        <span className="effect"><a href="https://discord.gg/UQg8JuWQHv" target="_blank" rel="noopener noreferrer">Join Discord</a></span>
                                </button>
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            {/* <div className="container">
                <div className="row">
                    <div className="col-xl-5 col-md-12">
                        <div className="content-about mobie-40" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title st2">
                                <p className="text-about">FAIR DISTRIBUTION</p>
                                <h7 className="text-about-1">(BONDING CURVES ARE A PONZI)</h7>
                                <p></p>
                                
                                <p>There are no bonding curves here. Buying a Ella costs 0.02 ETH. There are no price tiers; Ella membership costs the same for everyone.</p>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-xl-7 col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            <p className="text-about-2"> Note: Fifty Two randomized Ellas are being withheld from public sale. These will be used for giveaways, exploration rewards—and for the creators' Ella clearance.</p>
                        </div>
                    </div>
                    
                </div>
            </div> */}
        </section>
    );
}

export default About;