import React , {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'
import AboutItem from '../about-item';
import Button from '../../../components/button';

About.propTypes = {
    data : PropTypes.array,
};

function About(props) {

    const {data} = props;

    const [dataBlock] = useState(
        
        {
            subtitle : 'About us',
            title: 'Welcome to the Ella Network',
            desc : 'Ella is a collection of 5,200 Ella NFTs—unique digital collectibles living on the Polygon blockchain. Your Ella doubles as your Ella Network clearance card, and grants access to clearance-only benefits. The first of which is your Ella avatar profile image, then access to your unique Ellas clearance strip; a short story, part of the greater picture. Ella holders will also have first dibs on the Ella collectible card packs & Ella Three D, for our future role playing game. Future areas and perks can be unlocked by the community through roadmap activation.'
        }
    )

    return (
        <section className="tf-section tf-about style2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-about" data-aos="fade-up" data-aos-duration="800">
                            <div className="tf-title">
                                <p className="h8 sub-title">{dataBlock.subtitle}</p>
                                <h4 className="title">{dataBlock.title}</h4>
                            </div>
                            <p className='pd20'>{dataBlock.desc}</p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="wrap-about" data-aos="fade-up" data-aos-duration="800">
                            {
                                data.map(item => (
                                    <AboutItem key={item.id} item={item} />
                                ))
                            }
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="btn-about" data-aos="fade-up" data-aos-duration="800">
                            <Button title='View collection' path='/collection' />
                            <button to="" className="tf-button btn-effect">
                                        <span className="boder-fade"></span>                                     
                                        <span className="effect"><a href="https://discord.gg/UQg8JuWQHv">Join Discord</a></span>
                                    </button>
                        </div>
                    </div>
                </div>
            </div>
            
            
        </section>
    );
}

export default About;